import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  body: {
    marginLeft : 50,
    marginRight: 50,
    marginBottom: 100
    },
  card: {
    display: 'flex',

    },
  details: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    minWidth: 150,
    minHeight: 125
    },
  title: {
    background: 'grey',
    align: 'center'
  },
  heroContent: {
    maxWidth: 600,
    margin: 'auto',
    //padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
    padding: `${theme.spacing(8)} 10 ${theme.spacing(6)} 10`,

  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
})


class Offre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded: false, open1: false, open2: false, open3: false};

    // This binding is necessary to make `this` work in the callback
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleOpenClick1 = this.handleOpenClick1.bind(this);
    this.handleOpenClick2 = this.handleOpenClick2.bind(this);
    this.handleOpenClick3 = this.handleOpenClick3.bind(this);
  }

  handleExpandClick() {
    this.setState(state => ({expanded: !state.expanded}));
  };

  handleOpenClick1 = () => {
    this.setState({open1: true});
  };
  handleOpenClick2 = () => {
    this.setState({open2: true});
  };
  handleOpenClick3 = () => {
    this.setState({open3: true});
  };




  render () {
    const {classes} = this.props;

    const handleClose = () => {
      this.setState({open1: false, open2: false, open3: false});
    };

    return (<div className={classes.body}>

          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Voir les données pour décider
          </Typography>

          <div>
          <Grid spacing={4} container alignItems="stretch">
            <Grid item  xs={12} sm={12} md={4}>
                <Card className={classes.card} elevation={8}>
                  <CardActionArea onClick={this.handleOpenClick3}>
                    <div className={classes.details}>
                      <CardMedia className={classes.media}
                        image= '/dataBW.png' alt=''
                      />
                      <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                          (Open) Data
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                        Collecter, Rectifier, Consolider, Diffuser
                        </Typography>
                      </CardContent>
                    </div>
                  </CardActionArea>
                  <Dialog
                      open={this.state.open3}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                  <DialogTitle id="responsive-dialog-title">Vous aider à atteindre vos objectifs de données</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Grid spacing={2} container >
                          <Grid item xs={3}> <img src="dataBW.png" width= "120" alt='' /></Grid>
                          <Grid item xs={9}><i> Les données ouvertes ou privées sont à la base de tout le reste. Sans données pas de compréhension, pas de décision</i></Grid>
                      </Grid>
                      <ul>
                        <li><img src="conseil.png" width= "30" alt=''/><b> Conseil </b>:<br/>
                          <ul>
                           <li>recherche d'open data</li>
                           <li>comment manipuler, traiter les données, quels outils open source ?</li>
                           <li>quels hébergements choisir ?</li>
                           <li>au temps passé et même en petite quantité (à partir de 100€ pour une heure) ou au forfait</li>
                          </ul>
                        </li>
                        <li><img src="formation.png" height= "30" alt=''/><b> Formation </b>:<br/>
                          <ul>
                           <li>les formats de données: xls, csv, json, geojson, topojson, shapefile, ... Et les outils à utiliser pour les manipuler</li>

                          </ul>
                        </li>
                        <li><img src="assistance.png" width= "30" alt=''/><b> Assistance </b>:<br/>
                          <ul>
                           <li>aider vos équipes à rechercher, rectifier, consolider et diffuser vos données</li>
                           <li>au temps passé ou au forfait</li>
                          </ul>
                        </li>
                        <li><img src="applicationsBW.png" width= "30" alt=''/><b> Réalisation </b>:<br/>
                        <ul>
                           <li>implication directe dans les travaux et réalisations</li>
                           <li>sur site ou à distance</li>
                          </ul>
                        </li>                       
                      </ul>
                      Exemples
                    </DialogContentText> 
                  </DialogContent>
                </Dialog>
              </Card>
            </Grid>
            <Grid item  xs={12} sm={12} md={4}>
                <Card className={classes.card} elevation={8}>
                  <CardActionArea onClick={this.handleOpenClick1}>
                    <div className={classes.details}>
                      <CardMedia className={classes.media}
                        image= '/datavizBW.png'
                      />
                      <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                          Dataviz
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Explorer, Visualiser, Analyser, Comprendre
                        </Typography>
                      </CardContent>
                    </div>
                  </CardActionArea>
                  <Dialog
                      open={this.state.open1}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">Vous aider à atteindre vos objectifs Dataviz</DialogTitle>
                    
                    <DialogContent>
                      <DialogContentText>
                      <Grid spacing={2} container >
                          <Grid item xs={3}> <img src="datavizBW.png" width= "120" alt=''/></Grid>
                          <Grid item xs={9}><i> Les données rendues visibles permettent de découvrir des vérités insoupçonnées et de répondre aux questions des utilisateurs</i></Grid>
                      </Grid>
                      <ul>
                        <li><img src="conseil.png" width= "30" alt=''/><b> Conseil </b>:<br/>
                          <ul>
                           <li>évaluer des visualisations existantes</li>
                           <li>proposer des améliorations</li>
                           <li>conception de visualisations adaptées</li>            
                           <li>au temps passé même en petite quantité (à partir de 100€ pour une heure) ou au forfait</li>
                          </ul>
                        </li>
                        <li><img src="formation.png" height= "30" alt=''/><b> Formation </b>:<br/>
                          <ul>
                           <li>dataviz sur une journée à partir de 5 personnes et 1200€: théorie et exercices</li>
                           <li>séance d'initiation à la dataviz entre 12h et 14h autour d'un sandwich </li>
                           <li>formation à l'outil choisi: excel options avancées, d3js, observable, Vega, Tableau, plotly... </li>
                          </ul>
                        </li>
                        <li><img src="assistance.png" width= "30" alt=''/><b> Assistance </b>:<br/>
                          <ul>
                           <li>participation à la réalisation avec l'équipe</li>
                           <li>au temps passé ou au forfait</li>
                          </ul>
                        </li>
                        <li><img src="applicationsBW.png" width= "30" alt=''/><b> Réalisation </b>:<br/>
                        <ul>
                           <li>développement de composants réutilisables (React/ Angular/ Vue)</li>
                           <li>réalisation de visualisations (Excel/ Google sheet, d3js, Vega, ...)</li>
                           <li>mise en place d'hébergement small ou big data</li>
                          </ul>
                        </li>                       
                      </ul>
                      Exemples
                      </DialogContentText> 
                    </DialogContent>
                  </Dialog>
                </Card>

            </Grid>
            <Grid item  xs={12} sm={12} md={4} >
                <Card className={classes.card} elevation={8}>
                  <CardActionArea onClick={this.handleOpenClick2}>
                    <div className={classes.details}>
                      <CardMedia className={classes.media}
                        image= '/dashboardBW.png'
                      />
                      <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                          Dashboard
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Mesurer, Expliquer, Piloter, Améliorer
                        </Typography>
                      </CardContent>
                  </div>
                  </CardActionArea>
                  <Dialog
                      open={this.state.open2}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">Vous aider à atteindre vos objectifs de Tableau de Bord</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                      <Grid spacing={2} container >
                          <Grid item xs={3}> <img src="dashboardBW.png" width= "120" alt=''/></Grid>
                          <Grid item xs={9}><i> Le tableau de bord efficace est constitué des indicateurs clés qui regardent vers l'avant. Comment les choisir ? Les représenter ?</i></Grid>
                      </Grid>
                      <ul>
                        <li><img src="conseil.png" width= "30" alt=''/><b> Conseil </b>:<br/>
                          <ul>
                           <li>interviews pour établir les buts stratégiques</li>
                           <li>identification des indicateurs clés</li>
                          </ul>
                        </li>
                        <li><img src="formation.png" height= "30" alt=''/><b> Formation </b>:<br/>
                          <ul>
                           <li>séance d'initiation au tableau de bord entre 12h et 14h autour d'un sandwich </li>
                          </ul>
                        </li>
                        <li><img src="assistance.png" width= "30" alt=''/><b> Assistance </b>:<br/>
                          <ul>
                           <li>accompagnement à la mise en place des processus tableau de bord</li>
                           <li>aide au déploiement d'outils support: Notion, ...</li>
                          </ul>
                        </li>
                        <li><img src="applicationsBW.png" width= "30" alt=''/><b> Réalisation </b>:<br/>
                        <ul>
                           <li>réalisation d'application complète (Excel / Google sheet, Web App mobiles, ...)</li>
                           <li>déploiement hébergements américains (mLabs, AWS, NodeChef, ...) ou français (OVH, OnLine, ...)</li>
                          </ul>
                        </li>                       
                      </ul>
                      Exemples
                      </DialogContentText> 
                    </DialogContent>
                  </Dialog>
                </Card>
              </Grid>
          </Grid>
         </div>

      </div>)
  
  };
}

Offre.propTypes = {
  classes: PropTypes.object.isRequired,
};
//export default Offre;
export default withStyles(styles)(Offre);